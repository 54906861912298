import { ConfigEnvironment } from '../enums/configEnvironment';
import { Environment } from '../enums/environment';

export const getDeployedEnvironment = (): Environment => {
  const { hostname } = window.location;
  if (hostname.includes('go.remote')) return Environment.SSRONLY;
  if (
    hostname.includes('.pr.') ||
    hostname.includes('.staging.') ||
    hostname.includes('localhost')
  ) {
    return Environment.DEVELOPMENT;
  }

  if (hostname.includes('.preview')) {
    return Environment.PREVIEW;
  }

  return Environment.PRODUCTION;
};

export const getConfigEnvironment = (): ConfigEnvironment => {
  const { hostname } = window.location;
  if (
    hostname.includes('.pr.') ||
    hostname.includes('.staging.') ||
    hostname.includes('localhost')
  ) {
    return ConfigEnvironment.DEFAULT;
  }

  if (hostname.includes('.preview')) {
    return ConfigEnvironment.PREVIEW;
  }

  return ConfigEnvironment.PRODUCTION;
};
