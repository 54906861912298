import React from 'react';
import TenantSelector, { Background } from '@cognite/cdf-hub-tenant-selector';
import { getSidecarForCluster } from '../../utils/sidecar';
import { getDeployedEnvironment } from '../../utils/environment';
import { Environment } from '../../enums/environment';
import { clusters } from './Clusters';
import useEnv from '../../customHooks/useEnv';

export default function LoginView() {
  // const [cluster, setCluster] = useCluster();
  const [env, setEnv] = useEnv();
  const sidecar = getSidecarForCluster(env);

  return (
    <Background>
      <TenantSelector
        appName="Cognite Remote"
        clientId={sidecar.aadApplicationId}
        clusters={clusters}
        cluster={env}
        setCluster={setEnv}
        move={(project: string) => {
          window.location.href = `/${project}${env ? `?cluster=${env}` : ''}`;
        }}
        isProduction={getDeployedEnvironment() === Environment.PRODUCTION}
      />
    </Background>
  );
}
