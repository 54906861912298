import React from 'react';
import classes from './Modal.module.scss';
import rect from '../../../assets/rect-white.svg';

interface Props {
  headImage: string;
  title: string;
  text: string;
  background?: string;
  acceptText?: string;
  onAccept?: () => void;
  onClose?: () => void;
}

export const Modal: React.FC<Props> = ({
  headImage,
  title,
  text,
  background,
  acceptText = '',
  onAccept,
  onClose = () => {},
}: Props) => {
  const bgImage = background ? { backgroundImage: `url(${background})` } : {};

  return (
    <div className={classes.modalContainer} style={bgImage}>
      <div className={classes.box}>
        <div
          onClick={onClose}
          onKeyDown={onClose}
          className={classes.close}
          role="button"
          tabIndex={0}
        >
          <></>
        </div>
        <div>
          <img src={headImage} className={classes.top} alt="head" />
          <img src={rect} className={classes.rect} alt="react" />
        </div>
        <div className={classes.bottom}>
          <div className={classes.title}>{title}</div>
          <div className={classes.content}>{text}</div>
          <div>
            {onAccept && (
              <div
                onClick={onAccept}
                onKeyDown={onAccept}
                className={classes.button}
                role="button"
                tabIndex={0}
              >
                {acceptText}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
