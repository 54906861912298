import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
// import { Provider } from 'react-redux';
// import { Router } from 'react-router-dom';
// import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import AppWrapper from './AppWrapper';

import '@cognite/cogs.js/dist/cogs.css';
import './index.scss';

Sentry.init({
  dsn: 'https://39b9d6da5b9942aa9658c952dcd26996@o124058.ingest.sentry.io/5740656',
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_VERSION,
  ignoreErrors: ['Network request failed', 'Network Error'],
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
