import React from 'react';
import { ToastContainer } from '@cognite/cogs.js';
import { connect } from 'react-redux';
import { IAppState, IAuthData } from '../interfaces/interfaces';
import BasePlayer from './Players/BasePlayer';
import { ErrorPage } from './ErrorPage/ErrorPage';
import { useSessionOut } from '../customHooks/useSessionOut';

export interface AppProps {
  authData: IAuthData;
  cluster: string | null;
  isCurrentStaticVersion: boolean;
}

const App: React.FC<Props> = ({
  error,
  isLoggedOut,
  isCurrentStaticVersion,
  cluster,
  authData,
  accessToken,
}: Props) => {
  const [logout] = useSessionOut();

  if (isLoggedOut) {
    logout();
  }

  if (error) {
    return <ErrorPage text={error} />;
  }

  return (
    <>
      <ToastContainer />
      <BasePlayer
        isCurrentStaticVersion={isCurrentStaticVersion}
        authData={authData}
        cluster={cluster}
        accessToken={accessToken}
      />
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    error: state.appError,
    accessToken: state.authData?.access_token,
    isLoggedOut: state.isLoggedOut,
  };
};

export type Props = AppProps & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(App);
