import { createSlice } from '@reduxjs/toolkit';
import { IAppState } from '../interfaces/interfaces';

const appState: IAppState = {
  isWSConnected: false,
  appError: null,
  authData: null,
  loginType: null,
  loginInputs: null, // tenant and environment
  azureTenant: null,
  isLoggedOut: false,
};

const appStateSlice = createSlice({
  name: 'AppState',
  initialState: appState,
  reducers: {
    wsConnect: (state: { isWSConnected: boolean }) => {
      state.isWSConnected = true;
    },
    wsDisconnect: (state: { isWSConnected: boolean }) => {
      state.isWSConnected = false;
    },
    // With the current implementation, reset the app when any disconnection occurs
    disconnect: (state) => {
      state.isWSConnected = false;
      state.loginInputs = null;
    },
    setTenantState: (state: { loginInputs: any }, action: { payload: any }) => {
      state.loginInputs.tenant = action.payload;
    },
    setAuthDataState: (state: { authData: any }, action: { payload: any }) => {
      state.authData = action.payload;
    },
    setEnvironmentState: (
      state: { loginInputs: any },
      action: { payload: any }
    ) => {
      state.loginInputs.environment = action.payload;
    },
    setLoginTypeState: (
      state: { loginType: any },
      action: { payload: any }
    ) => {
      state.loginType = action.payload;
    },
    setErrorState: (state: { appError: any }, action: { payload: any }) => {
      state.appError = action.payload;
    },
    setLoginInputsState: (
      state: { loginInputs: any },
      action: { payload: any }
    ) => {
      state.loginInputs = action.payload;
    },
    setAzureTenantState: (
      state: { azureTenant: any },
      action: { payload: any }
    ) => {
      state.azureTenant = action.payload;
    },
    onLogout: (state: IAppState, action: { payload: boolean }) => {
      state.isLoggedOut = action.payload;
    },
  },
});

export const {
  wsConnect,
  wsDisconnect,
  disconnect,
  setErrorState,
  setTenantState,
  setAuthDataState,
  setEnvironmentState,
  setLoginTypeState,
  setLoginInputsState,
  setAzureTenantState,
  onLogout,
} = appStateSlice.actions;

export const isConnected = (state: IAppState) => state.isWSConnected;

export const isAppError = (state: IAppState) => state.appError;

export const isLoggedIn = (state: IAppState) => {
  return (
    state.loginInputs?.tenant !== null &&
    state.loginInputs?.tenant !== undefined
  );
};

export const getTenantState = (state: IAppState) => {
  return state.loginInputs?.tenant;
};

export const getEnvironmentState = (state: IAppState) => {
  return state.loginInputs?.environment;
};

export const getLoginTypeState = (state: IAppState) => state.loginType;

export const getAuthDataState = (state: IAppState) => state.authData;

export const getLoginInputsState = (state: IAppState) => state.loginInputs;

export default appStateSlice.reducer;
