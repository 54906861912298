import React, { memo, Suspense, useEffect } from 'react';
import { IAuthData } from '../../interfaces/interfaces';
import { meassureSpeed } from '../../utils/helper';
import { DownloadNotifier } from '../DownloadNotifier/DownloadNotifier';

const PureWebPlayerWrapper = React.lazy(
  () => import('./PureWeb/PureWebPlayerWrapper')
);
const PixelStreamingPlayer = React.lazy(
  () => import('./PixelStreaming/PixelStreamingPlayer')
);

interface Props {
  isCurrentStaticVersion: boolean;
  accessToken: string | undefined;
  cluster: string | null;
  authData: IAuthData;
}

const BasePlayer: React.FC<Props> = ({
  isCurrentStaticVersion,
  accessToken,
  cluster,
  authData,
}: Props) => {
  useEffect(() => {
    meassureSpeed();
  }, []);

  if (isCurrentStaticVersion) {
    return (
      <Suspense fallback={<></>}>
        <DownloadNotifier />
        <PixelStreamingPlayer cluster={cluster} authData={authData} />
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<></>}>
      <DownloadNotifier />
      <PureWebPlayerWrapper
        accessToken={accessToken}
        cluster={cluster}
        authData={authData}
      />
    </Suspense>
  );
};

export default memo(BasePlayer);
