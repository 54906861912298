import { useEffect, useState } from 'react';
import { getFlow } from '@cognite/auth-utils';
import { useDispatch } from 'react-redux';
import { useSDK } from '@cognite/sdk-provider';

import { getSidecarForCluster, Sidecar } from '../utils/sidecar';
import { useCluster } from './useCluster';
import { IAuthData } from '../interfaces/interfaces';
import { useProject } from './useProject';
import { setAuthDataState } from '../redux/appState';

export const useAuthState = (): [
  IAuthData,
  Sidecar,
  (isAuthed: boolean) => void
] => {
  const [authData, setAuthData] = useState<IAuthData>({} as IAuthData);

  const sdk = useSDK();
  const dispatch = useDispatch();
  const [cluster] = useCluster();
  const project = useProject();
  const { flow, options } = getFlow(project, cluster); // fix for getting proper login
  const sidecar: Sidecar = getSidecarForCluster(cluster);

  const azureAuth = async (isAuthed: boolean) => {
    console.log('useAuthState - azureAuth - isAuthed', isAuthed);
    try {
      let isAuthenticated = isAuthed;
      if (!isAuthed) {
        console.log('useAuthState - azureAuth - loginWithOAuth');
        await sdk.loginWithOAuth({
          type: 'AAD_OAUTH',
          options: {
            cluster: sidecar.cdfCluster === '' ? 'api' : sidecar.cdfCluster,
            clientId: sidecar.aadApplicationId,
            tenantId: options?.directory,
            signInType: {
              type: 'loginRedirect',
            },
          },
        });
        sdk.setProject(project);
        isAuthenticated = await sdk.authenticate();
      }

      const cdfToken = await sdk.getCDFToken();
      const idToken = await sdk.getIdToken();

      console.log('useAuthState - azureAuth - accessToken', cdfToken);
      const authObj = {
        access_token: cdfToken || '',
        id_token: idToken,
        isAuthenticated,
        project,
      };
      setAuthData({ ...authObj });
      console.log('azureAuth - authObj', authObj);
      dispatch(setAuthDataState({ ...authObj }));
    } catch (error) {
      console.log('Error - useAuthState', error);
    }
  };

  const cogniteAuth = async (isAuthed: boolean) => {
    console.log('useAuthState:: cogniteAuth - isAuthed', isAuthed);

    const urlParams = new URLSearchParams(window.location.search);
    const env = urlParams.get('cluster') || '';

    console.log('useAuthState::Env and Project', env, project);
    console.log('useAuthState::Cluster', cluster);
    console.log(
      'useAuthState:: cogniteAuth - authflow',
      getFlow(project, env).flow
    );
    try {
      let isAuthenticated = isAuthed;
      if (!isAuthed) {
        if (cluster) {
          sdk.setBaseUrl(`https://${cluster}.cognitedata.com`);
        }
        await sdk.loginWithOAuth({
          type: 'CDF_OAUTH',
          options: {
            project,
          },
        });
        isAuthenticated = await sdk.authenticate();
      }
      console.log('useAuthState::isAuthenticated', isAuthenticated);
      if (isAuthenticated) {
        const cdfToken = await sdk.getCDFToken();
        const authObj = {
          access_token: cdfToken || '',
          id_token: '',
          isAuthenticated,
          project,
        };
        setAuthData(authObj);
        dispatch(setAuthDataState({ ...authObj }));
      }
    } catch (error) {
      console.log('Error - useAuthState', error);
    }
  };

  const auth = (isAuthed: boolean) => {
    console.log('useAuthState - auth', flow);
    if (flow === 'AZURE_AD') {
      azureAuth(isAuthed);
    } else {
      cogniteAuth(isAuthed);
    }
  };

  useEffect(() => {
    auth(false);
  }, []);

  return [authData, sidecar, auth];
};
