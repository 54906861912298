import React from 'react';
import * as Sentry from '@sentry/react';
import { Router } from 'react-router-dom';
import { SDKProvider } from '@cognite/sdk-provider';
import { CogniteClient } from '@cognite/sdk';
import { createBrowserHistory } from 'history';
import { Provider as ReduxProvider } from 'react-redux';
import store from './redux/store';
import Routes from './routes';
import { manageVersion } from './utils/versionManager';
import { APP_VERSION } from './utils/localStorageKeys';
import { ErrorComponent } from './components/Players/PureWeb/ErrorComponent/ErrorComponent';

const Client = new CogniteClient({
  appId: 'cognite-remote',
});

const AppWrapper = () => {
  console.log(
    `${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION} loaded.`
  );
  const history = createBrowserHistory();
  manageVersion(
    localStorage.getItem(APP_VERSION) ?? '',
    process.env.REACT_APP_VERSION || ''
  );

  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorComponent
          title="An error has occured"
          message="Please try reloading the app."
          skipLogs
        />
      }
    >
      <ReduxProvider store={store}>
        <SDKProvider sdk={Client}>
          <Router history={history}>
            <Routes />
          </Router>
        </SDKProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  );
};

export default AppWrapper;
