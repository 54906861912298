import axios from 'axios';
import * as Sentry from '@sentry/react';
import { AuthFlow } from '@cognite/auth-utils/dist';
import { UUID } from './cookieKeys';

export const getAuthType = (flow?: AuthFlow) => {
  switch (flow) {
    case 'AZURE_AD':
      return 'OpenIDConnect';
    case 'COGNITE_AUTH':
      return 'OAuthToken';
  }
  return null;
};

export const readUUId = () => {
  const storedUUID = getCookie(UUID);
  return storedUUID;
};

export const getCookie = (name: string) => {
  const cookie: Record<string, string> = {};
  document.cookie.split(';').forEach((el) => {
    const [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });
  return cookie[name];
};

export const withCluster = (url: string, cluster: string) => {
  if (cluster === '') {
    return url.replace('.<cluster>.', `.`);
  }
  return url.replace('.<cluster>.', `.${cluster}.`);
};

export const getClientNetworkSpeed = () => {
  if (navigator.connection) {
    const speed = navigator.connection.downlink;
    console.log('Netowork Speed', speed);
    return speed;
  }
  return 'API NOT SUPPORTED';
};

export const meassureSpeed = async (): Promise<string> => {
  try {
    const resourceURI = process.env.REACT_APP_SPEED_CHECK_URL ?? '';
    const startTime = new Date().getTime();
    const response = await axios(resourceURI, {
      method: 'GET',
      responseType: 'stream',
      headers: {},
    });
    const lenInBytes: number = parseInt(
      response.headers['content-length'] ?? '',
      10
    );
    console.log('length', lenInBytes);
    const endTime = new Date().getTime();
    const duration = (endTime - startTime) / 1000;
    console.log('duration', duration);
    const bps = lenInBytes / duration;
    const Kbps = bps / 1024;
    const MBps = Kbps / 1024;
    const Mbps = (MBps * 8).toFixed(2);
    console.log('Speed in Mbps', Mbps);
    return `${Mbps}Mbps`;
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    return 'FAILED TO CHECK BANDWIDTH';
  }
};

export const getUser = async (idToken: string) => {
  if (!idToken) throw new Error('No token');

  const r = await fetch('https://graph.microsoft.com/v1.0/me', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  const res = await r.json();

  return res;
};
