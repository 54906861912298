import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { getFlow } from '@cognite/auth-utils';
import { useProject } from './customHooks/useProject';
import { useAuthState } from './customHooks/useAuthState';
import { EventService } from './services/event.service';
import { EventType } from './enums/eventType';
import LoginView from './components/Login/Login';
import App from './components/App';
import store from './redux/store';

import { getAuthType } from './utils/helper';

const AppRoutes = () => {
  const { path } = useRouteMatch();
  const [authData, sidecar, auth] = useAuthState();
  /**
   * session refresh
   */
  useEffect(() => {
    const subscription = EventService.listen(EventType.REFRESH_TOKEN).subscribe(
      () => {
        const isAuthed = store.getState().authData?.isAuthenticated || false;
        console.log('AppRoutes - Session Refresh - isAuthed', isAuthed);
        auth(isAuthed);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (authData.access_token) {
    const urlParams = new URLSearchParams(window.location.search);
    const env = urlParams.get('cluster') || '';
    const { project } = authData;
    const { flow, options } = getFlow(project, env);
    const authType = getAuthType(flow);
    const azureTenant = options?.directory;

    Sentry.setTags({
      tenant: project,
      loginType: authType,
      azureTenant,
    });

    return (
      <Switch>
        <Route path={`${path}/`} exact>
          <App
            authData={authData}
            cluster={sidecar.cdfCluster}
            isCurrentStaticVersion={sidecar.isCurrentStaticVersion}
          />
        </Route>
      </Switch>
    );
  }
  return null;
};

const Routes = () => {
  const project = useProject();

  return (
    <Switch>
      <Route exact path="/" component={LoginView} />
      <Route path={`/${project}`}>
        <AppRoutes />
      </Route>
    </Switch>
  );
};

export default Routes;
