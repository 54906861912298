/* eslint-disable no-underscore-dangle */
import { Environment } from '../enums/environment';
import { getDeployedEnvironment } from './environment';

export type IntercomSetting = {
  disableIntercom: boolean;
};

export type Sidecar = {
  __sidecarFormatVersion: number;
  applicationId: string;
  aadApplicationId: string;
  applicationName: string;
  appsApiBaseUrl: string;
  cdfApiBaseUrl: string;
  cdfCluster: string;
  docsSiteBaseUrl: string;
  intercom: string;
  nomaApiBaseUrl: string;
  isCurrentStaticVersion: boolean;
  intercomSettings: IntercomSetting;
  disableLegacyLogin: boolean;
  environment: string; // new property to hold deployed env
};

const generateBaseUrls = (cluster: string, prod = false) => {
  switch (cluster) {
    case 'ew1': {
      return {
        appsApiBaseUrl: 'https://apps-api.staging.cognite.ai',
        cdfApiBaseUrl: 'https://api.cognitedata.com',
        cdfCluster: '',
      };
    }
    default: {
      return {
        appsApiBaseUrl: prod
          ? `https://apps-api.${cluster}.cognite.ai`
          : `https://apps-api.staging.${cluster}.cognite.ai`,
        cdfApiBaseUrl: `https://${cluster}.cognitedata.com`,
        cdfCluster: cluster,
      };
    }
  }
};

const getAADId = (deployedEnv: Environment): { aadApplicationId?: string } => {
  if (deployedEnv === Environment.SSRONLY) return {}; // return empty so it will use the value in public/sidecar.js
  if (deployedEnv === Environment.PRODUCTION) {
    return {
      aadApplicationId: '58a47358-8f43-4f2e-ad00-5b66e26cf4ef',
    };
  }
  return {
    aadApplicationId: '5c560cf9-dc59-4d5f-817e-ec5fb04913ea',
  };
};

let CLUSTER = 'ew1';
/**
 * @param cluster optional param to generate sidecar relevant to env
 * @returns {Sidecar} Object
 */
export const getSidecarForCluster = (cluster?: string | null) => {
  CLUSTER = cluster || 'ew1';

  const deployedEnv = getDeployedEnvironment();
  const isPROD = deployedEnv === Environment.PRODUCTION;
  const sidecar: Sidecar = {
    ...((window as any).__cogniteSidecar || {}),
    ...generateBaseUrls(CLUSTER, isPROD),
    __sidecarFormatVersion: 1,
    applicationId: 'cognite-remote',
    applicationName: 'Cognite Remote',
    docsSiteBaseUrl: 'https://docs.cognite.com',
    intercomSettings: {
      disableIntercom: true,
    },
    disableLegacyLogin: false,
    environment: deployedEnv,
    ...getAADId(deployedEnv),
  };

  return sidecar;
};
