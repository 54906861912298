import React from 'react';
import { Icon, Button } from '@cognite/cogs.js';
import Draggable from 'react-draggable';
import DownloadItem from './DownloadItem';
import { IDownloadProgressEvent } from './DownloadNotifier';

// Why ? - https://github.com/react-grid-layout/react-draggable/issues/652
const DraggableWrapper: any = Draggable;

interface IDownloadStatusBoxProps {
  downloads: IDownloadProgressEvent[];
  removeDownload: (id: string) => void;
  isAllCompleted: boolean;
  hideBox: () => void;
  close: () => void;
}

const DownloadStatusBox = ({
  downloads,
  removeDownload,
  isAllCompleted,
  hideBox,
  close,
}: IDownloadStatusBoxProps) => {
  const cancelAllDownloads = () => {
    downloads.forEach((item) => {
      removeDownload(item.downloadId);
    });
  };

  const downloadItems = downloads.map((_d) => {
    return (
      <DownloadItem
        download={_d}
        key={_d.downloadId}
        removeDownloadItem={removeDownload}
      />
    );
  });

  const loader = () => {
    if (isAllCompleted) {
      return (
        <>
          <Icon type="CheckmarkFilled" />
          Downloads complete
        </>
      );
    }
    return (
      <>
        <Icon type="Loader" /> Downloading
      </>
    );
  };

  const collapseOrClose = () => {
    if (isAllCompleted) {
      return <Icon type="Close" className="collapse-icon" onClick={close} />;
    }
    return <Icon type="Collapse" className="collapse-icon" onClick={hideBox} />;
  };

  const getActionButton = () => {
    if (isAllCompleted) {
      return (
        <Button type="primary" icon="Close" onClick={close}>
          Close
        </Button>
      );
    }
    return (
      <Button
        type="secondary"
        variant="inverted"
        icon="Close"
        onClick={cancelAllDownloads}
      >
        Cancel downloads
      </Button>
    );
  };

  return (
    <DraggableWrapper bounds="parent">
      <div className="download-status-box">
        <div className="header-section">
          <div className="title-side">{loader()}</div>
          {collapseOrClose()}
        </div>
        <div className="mid-section">{downloadItems}</div>
        <div className="footer-section">{getActionButton()}</div>
      </div>
    </DraggableWrapper>
  );
};

export default DownloadStatusBox;
