import { APP_VERSION } from './localStorageKeys';

export const manageVersion = (stored: string, latest: string) => {
  console.log(`manageVersion version: ${stored}  ${latest}`);
  if (stored !== latest) {
    console.log(`manageVersion mismatch`);
    localStorage.setItem(APP_VERSION, latest);
    window.location.reload();
  }
};
