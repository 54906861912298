// import { EndSessionRequest } from '@azure/msal-browser';
import { useSDK } from '@cognite/sdk-provider';
import { getFlow } from '@cognite/auth-utils';
import { useProject } from './useProject';

const AUTH_RESULT_STORAGE_KEY = 'authResult';
const KEY_LAST_TENANT = 'last_CDF_project';

export const useSessionOut = () => {
  const sdk = useSDK();
  const project = useProject();

  const logout = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const env = urlParams.get('cluster') || '';

    const { flow } = getFlow(project, env);

    console.log('useSessionOut - project', project);
    console.log('useSessionOut - env', env);
    // @ts-expect-error azureAdClient is private?
    // const { azureAdClient } = sdk;

    console.log('useSessionOut - azureAdClient SDK', sdk);
    console.log('useSessionOut - flow that is using now', flow);

    if (flow === 'AZURE_AD') {
      window.location.replace(window.location.origin);
      // cleanCache();
      // try {
      //   const logOutRequest: EndSessionRequest = {
      //     account: azureAdClient.getAccount(),
      //     postLogoutRedirectUri: '/',
      //   };
      //   azureAdClient.msalApplication.logout(logOutRequest);
      // } catch (err) {
      //   if (err instanceof Error) {
      //     console.log('logout fail', err.message);
      //   } else if (typeof err === 'string') {
      //     console.log('logout fail', err);
      //   }
      // } finally {
      //   window.location.replace(window.location.origin);
      // }
    } else {
      legacyLogout();
    }
  };

  const legacyLogout = async () => {
    /**
     * exactly what is happening on react container
     */
    localStorage.removeItem(AUTH_RESULT_STORAGE_KEY);
    localStorage.removeItem(KEY_LAST_TENANT);
    // saveFlow('UNKNOWN');
    window.location.replace(window.location.origin);

    /**
     * what should be happening. But OMV identity provider get messed up here
     */
    // try {
    //   const logoutUrl = await sdk.logout.getUrl();
    //   if (logoutUrl) {
    //     window.open(logoutUrl);
    //   }
    // } catch (err) {
    //   if (err instanceof Error) {
    //     console.log('logout fail', err.message);
    //   } else if (typeof err === 'string') {
    //     console.log('logout fail', err);
    //   }
    // } finally {
    //   window.location.pathname = '/';
    // }
  };

  // const cleanCache = () => {
  //   window.localStorage.clear();
  //   window.sessionStorage.clear();
  //   document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
  // }

  return [logout];
};
