import React from 'react';
import { Modal } from '../Presentational/Modal/Modal';
import bg from '../../assets/bg.jpg';
import headImage from '../../assets/crowd-icon.svg';

interface Props {
  text: string;
}
export const ErrorPage: React.FC<Props> = ({ text }: Props) => {
  const goToLogin = () => {
    window.location.href = window.location.origin;
  };

  return (
    <Modal
      title="Server Error"
      text={text}
      onAccept={goToLogin}
      onClose={goToLogin}
      acceptText="Go to Login"
      headImage={headImage}
      background={bg}
    />
  );
};
