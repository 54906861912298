import React, { useEffect, useState, useRef } from 'react';
import { EventService } from '../../services/event.service';
import { EventType } from '../../enums/eventType';
import DownloadStatusBox from './DownloadStatusBox';
import DownloadNotifierToggle from './DownloadNotifierToggle';

import './index.scss';

export enum DownloadStatus {
  NOTHING,
  IN_PROGRESS,
  COMPLETED,
  COMPLETED_WITH_ERRORS,
}
export interface IDownloadProgressEvent {
  downloadId: string;
  isFailed: boolean;
  progress: number;
  downloadName: string;
  abortRequest: () => void;
}

export const DownloadNotifier = () => {
  const [downloadList, setDownloadList] = useState<IDownloadProgressEvent[]>(
    []
  );
  const shadowDownloadList = useRef<IDownloadProgressEvent[]>([]);
  const [showDownloadStatusBox, setShowDownloadStatusBox] =
    useState<boolean>(false);
  const [isNotifierClose, setIsNotifierClose] = useState<boolean>(true);
  const [downloadState, setDownloadState] = useState<DownloadStatus>(
    DownloadStatus.NOTHING
  );

  const updateList = (download: IDownloadProgressEvent) => {
    const downloadListTemp = [...shadowDownloadList.current];
    const foundIndex = downloadListTemp.findIndex(
      (ele: IDownloadProgressEvent) => ele.downloadId === download.downloadId
    );

    // showing download box on initial download
    if (shadowDownloadList.current.length === 0 && download) {
      showDownloadBox();
    }
    if (foundIndex > -1) {
      downloadListTemp[foundIndex].progress = download.progress;
    } else {
      downloadListTemp.push(download);
    }
    setDownloadList([...downloadListTemp]);
    shadowDownloadList.current = downloadListTemp;
  };

  const removeDownloadItem = (downloadID: string) => {
    const downloadListTemp = [...downloadList];
    const foundIndex = downloadListTemp.findIndex(
      (ele: IDownloadProgressEvent) => ele.downloadId === downloadID
    );
    downloadListTemp[foundIndex].abortRequest();
    downloadListTemp.splice(foundIndex, 1);
    setDownloadList([...downloadListTemp]);
    shadowDownloadList.current = downloadListTemp;
    if (downloadListTemp.length === 0) {
      // if no download items left
      // we'll close the download tray and everything
      closeDownloadNotifier();
    }
  };

  useEffect(() => {
    const subsciption = EventService.listen(EventType.FILE_DOWNLOAD).subscribe(
      (data: IDownloadProgressEvent) => {
        updateList(data);
      }
    );

    return () => {
      subsciption.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (downloadList.length > 0) {
      setIsNotifierClose(false);
    }
  }, [downloadList]);

  // checking and updating download all status
  useEffect(() => {
    const completedOrFailedItemCount = downloadList.filter((item) => {
      return item.isFailed || item.progress === 100;
    }).length;
    if (
      completedOrFailedItemCount === downloadList.length &&
      downloadList.length > 0
    ) {
      const isThereFailedDownloads =
        downloadList.filter((item) => {
          return item.isFailed;
        }).length > 0;
      if (isThereFailedDownloads) {
        setDownloadState(DownloadStatus.COMPLETED_WITH_ERRORS);
      } else {
        setDownloadState(DownloadStatus.COMPLETED);
      }
      showDownloadBox();
    } else if (downloadList.length > 0) {
      setDownloadState(DownloadStatus.IN_PROGRESS);
    }
  }, [downloadList]);

  const closeDownloadNotifier = () => {
    setDownloadList([]);
    setDownloadState(DownloadStatus.NOTHING);
    setShowDownloadStatusBox(false);
    setIsNotifierClose(true);
    shadowDownloadList.current = [];
  };

  const showDownloadBox = () => {
    setShowDownloadStatusBox(true);
  };

  const hideDownloadBox = () => {
    setShowDownloadStatusBox(false);
  };

  const toggleDownloadBox = () => {
    setShowDownloadStatusBox(!showDownloadStatusBox);
  };

  const isAllCompleted =
    downloadState === DownloadStatus.COMPLETED ||
    downloadState === DownloadStatus.COMPLETED_WITH_ERRORS;

  const renderDownloadStatusBox = () => {
    if (!showDownloadStatusBox) return null;
    return (
      <DownloadStatusBox
        downloads={downloadList}
        removeDownload={removeDownloadItem}
        isAllCompleted={isAllCompleted}
        hideBox={hideDownloadBox}
        close={closeDownloadNotifier}
      />
    );
  };

  const renderComponents = () => {
    if (isNotifierClose) return null;
    return (
      <>
        {renderDownloadStatusBox()}
        <DownloadNotifierToggle
          clickHandle={toggleDownloadBox}
          status={downloadState}
        />
      </>
    );
  };

  return <div className="downloads-container">{renderComponents()}</div>;
};
