import { Subject } from 'rxjs';
import { EventType } from '../enums/eventType';

export class EventService {
  private static listenersMap: Record<string, Subject<any>> = {};

  public static listen(eventType: EventType): Subject<any> {
    if (!EventService.listenersMap[eventType]) {
      EventService.listenersMap[eventType] = new Subject<any>();
    }
    return EventService.listenersMap[eventType];
  }

  public static invoke(eventType: EventType, data?: any) {
    if (EventService.listenersMap[eventType]) {
      EventService.listenersMap[eventType].next(data);
    }
  }
}

// @ts-ignore
// window.invoke = EventService.invoke;
