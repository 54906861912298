import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { meassureSpeed } from '../../../../utils/helper';
import classes from './ErrorComponent.module.scss';

export interface ErrorProps {
  title: string;
  message?: string;
  data?: any;
  children?: JSX.Element;
  skipLogs?: boolean;
}

export const ErrorComponent: React.FC<ErrorProps> = ({
  title,
  message,
  skipLogs,
  data,
  children,
}: ErrorProps) => {
  useEffect(() => {
    logToSentry();
  }, []);

  const logToSentry = async () => {
    if (!skipLogs) {
      const scope = new Sentry.Scope();
      if (data) {
        scope.setExtra('AdditionalData', JSON.stringify(data));
      }
      const speed = await meassureSpeed();
      scope.setExtra('NetworkSpeed', speed);
      Sentry.captureMessage(message ?? title, 'error');
    }
  };

  return (
    <div className={classes.wrapper}>
      <div>
        {title}
        {message && (
          <>
            <br />
            <div>{message}</div>
          </>
        )}
        {children}
      </div>
    </div>
  );
};
