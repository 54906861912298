import React from 'react';
import { Icon } from '@cognite/cogs.js';

import { IDownloadProgressEvent } from './DownloadNotifier';

interface IDownloadItemProp {
  download: IDownloadProgressEvent;
  removeDownloadItem: (id: string) => void;
}

const DownloadItem = ({ download, removeDownloadItem }: IDownloadItemProp) => {
  const renderStatusIcon = (download: IDownloadProgressEvent) => {
    if (download.isFailed) {
      return <Icon type="CloseLarge" className="error" />;
    }
    if (download.progress === 100) {
      return <Icon type="CheckmarkFilled" className="success" />;
    }
    return (
      <Icon
        type="CloseLarge"
        className="close"
        onClick={() => removeDownloadItem(download.downloadId)}
      />
    );
  };

  const renderProgressBar = (download: IDownloadProgressEvent) => {
    let barColor = '#5874FF';
    if (download.isFailed) {
      barColor = '#BD241C';
    }
    return (
      <div className="progress-bar-shadow">
        <div
          className="progress-bar"
          style={{ backgroundColor: barColor, width: `${download.progress}%` }}
        />
      </div>
    );
  };

  return (
    <div className="download-item" key={download.downloadId}>
      <div className="top">
        <div className="left text">{download.downloadName}</div>
        <div className="right">{renderStatusIcon(download)}</div>
      </div>
      <div className="bottom">
        <div className="left">{renderProgressBar(download)}</div>
        <div className="right progress-text">{download.progress}%</div>
      </div>
    </div>
  );
};

export default DownloadItem;
