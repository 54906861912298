import React from 'react';
import { Icon } from '@cognite/cogs.js';
import Draggable from 'react-draggable';

import { DownloadStatus } from './DownloadNotifier';

const DraggableWrapper: any = Draggable;
interface IDownloadNotifierToggle {
  clickHandle: () => void;
  status: DownloadStatus;
}

const DownloadNotifierToggle = ({
  clickHandle,
  status,
}: IDownloadNotifierToggle) => {
  let styleClass = 'notification-tray-handler';
  let iconType = 'Loader';
  if (status === DownloadStatus.COMPLETED) {
    styleClass = 'notification-tray-handler-success';
    iconType = 'CheckmarkFilled';
  }
  if (status === DownloadStatus.COMPLETED_WITH_ERRORS) {
    styleClass = 'notification-tray-handler-error';
    iconType = 'Close';
  }

  return (
    <div className="notifier-tray-handle-wrap">
      <DraggableWrapper bounds="parent">
        <div
          className={`${styleClass}`}
          onClick={clickHandle}
          aria-hidden="true"
        >
          <Icon type={`${iconType}`} />
        </div>
      </DraggableWrapper>
    </div>
  );
};

export default DownloadNotifierToggle;
